import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router';
import App from './App';
import './Assets/CSS/App.css'

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);

root.render(

  <div id='rootContainer'>
    
    <BrowserRouter>     
      <App />
    </BrowserRouter >
  </div>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
