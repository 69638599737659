import React from 'react';
import { fetchSingleCategory } from '../JS/THREEJS/AddImages';
import * as BS from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/CSS/Categories.css'
import { Link, useLocation } from 'react-router-dom';

export default function () {

    const location = useLocation();
    const { id, src } = location.state || {};
    var category;
    if (id)
        category = fetchSingleCategory([id]);

    return (
        
        <div className='pages'>
            {
                category ? Object.entries(category).map((src, index) => (

                    Object.entries(src[1]).map((img, i, x) => (                        
                        img[1].includes('mp4') ?
                            <video className='vrVideos'
                                key={i}
                                controls
                                src={img[1]}
                                type='video/mp4'
                                autoPlay
                                muted
                            >
                                Your browser does not support HTML video.
                            </video>
                            :

                            <Link key={'category-' + i}
                                to={'/imageViewer/' + img[0]}
                                state={{ id: img[0], src: img[1], prevPath: {path:'/pages/' + src[0], id: src[0]} }}
                                className='imageLink'
                            >
                                <BS.Image thumbnail src={img[1]} alt={img[0] + '-image'} />
                            </Link>
                    ))

                ))
                    :
                    null
            }
        </div>
    )
}

