import React, {useRef, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/CSS/App.css';
import '../Assets/CSS/buttons.css'
import { Link, useLocation } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import closeXicon from '../Assets/Images/Icons/closeXiconWhite.png'

export default function AddViewer() {

    const location = useLocation();   
    const { id, src, prevPath } = location.state;

    const ref = useRef();
   
    return (
        <div id='imgContainer' >
            <div id='imgBackground' />
            <Link to={prevPath.path} state={{ id: prevPath.id }}>
                <button ref={ref} id='closeButton'>
                    <img  src={closeXicon} alt='close viewer icon' className='closeX'/>
                </button>
            </Link>
            <div id='imgWrapper'>
                <Image src={src} alt={'close-up of selected image: ' + id} />
            </div>
        </div>
    )
}