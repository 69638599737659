import React, { Component, useEffect, useRef } from 'react';
import './Assets/CSS/App.css';
import { Route, Routes, Outlet } from 'react-router';
import HomePage from './Components/HomePage.js';
import { setEyes } from './JS/THREEJS/Eyes.js';
import CategoryPage from './Components/CategoryPage.js';
import Logo from './Components/Logo.js'
import AddViewer from './Components/ImageViewer.js';
import MyNavbar from './Components/NavBar.js';
import './Assets/CSS/HomePage.css'
export default class App extends Component {
  componentDidMount() {

    setEyes();

    const config = { attributes: true, childList: true, subtree: true };
    const el = document.querySelector('.appContainer');
  }

  render() {
    return (
      <div className="appContainer">        
        <div id="canvasEl" />
         <MyNavbar />
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route path='/imageViewer/:id' element={<AddViewer/>}/>
            <Route index element={<HomePage />} />
            <React.Fragment id='pagewrapper'>
              <Route path="/pages/" >
                <Route path='/pages/:id' element={<CategoryPage  />} />
              </Route>
            </React.Fragment>
          </Route>
        </Routes>
        <Logo />
      </div>
    );
  }
}

/*<h4 id='loading'>Here We GO!!!</h4>
<div className='mainCanvas' >
<div className='mainTitle'>
  <img src={samsonart} id='samsonart' />
</div>
</div>*/
//import { loadCelestialBodies } from './JS/THREEJS/Planets';
//import { loadMainScene, scene } from './JS/THREEJS/Initial3DSetup';
//import { initMainScene } from './JS/THREEJS/MainScene';
//import samsonart from './Images/Planets/samsonart.png';
//import { loadLobbyContainer } from './Components/LobbyContainer';
//import * as BS from 'react-bootstrap';
// Scene setup
/*loadMainScene();
loadCelestialBodies();    
initMainScene();
loadLobbyContainer();
console.log("my scene", scene);*/