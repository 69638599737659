import * as React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { fetchAllCategories } from '../JS/THREEJS/AddImages.js';
import '../Assets/CSS/buttons.css';
import '../Assets/CSS/HomePage.css';
import { Image, NavLink } from 'react-bootstrap';
import { Link, Outlet } from 'react-router';
import fetchSplideRef from '../JS/Utils/Hooks.js';
//const FAVS = ['Marvel', 'zachGamer', 'Zach&Emma5', 'SkyMountains', 'TheBoys23Logo']
export var ALL_IMGS = fetchAllCategories();


export default function AddCarousel() {

    let pageList = [];
   
    const [ direction, setDirection ] = React.useState( 'ltr' );
    const [ aria, setAria ] = React.useState( 'horizontal' );
    const [ type, setType ] = React.useState( 'fade');


    let pages = [];
    Object.entries(ALL_IMGS).map((value) => {
        pages.push(value);
        return null;
    })
    pageList = pages;
    
    document.addEventListener('readystatechange', () => {
        console.log('ready state changed', document.readyState);

        console.log("window", window.innerWidth);
        
        if (window.innerWidth <= 450) {
          setDirection('ttb');
          setAria('vertical');
          setType('slide')
        } else {
            setDirection('ltr');
            setAria('horizontal');
            setType('fade')
        }
    
        window.addEventListener('resize', (e) => {
            const carousel = document.querySelector('.mainCarousel');
            console.log("resizing");
            if (!carousel) return;
            if (e.target.innerWidth <= 450) {
                setDirection('ttb');
                setAria('vertical');
                setType('slide')
              } else {
                  setDirection('ltr');
                  setAria('horizontal');
                  setType('fade');
              }
        });

        const el = document.querySelector('.vidSlide');
        if (!el) return;

        el.parentElement.classList.add('vidUL');

        const vidLI = document.querySelector('.vrCarousel').parentElement;
        const videoObserver = new MutationObserver(VidAutoPlay);
        const observerDefaultConfig = { attributes: true, attributeFilter: ['class'], attributeOldValue: true, subtree: true }
        videoObserver.observe(vidLI, observerDefaultConfig);


        document.querySelectorAll('.slidePage').forEach(slide => {
          console.log("slide", slide)
                slide.addEventListener('mouseover', () => {
                    slide.ariaHidden = false;
                })
                slide.addEventListener('mouseout', () => {
                    slide.ariaHidden = true;
                })
           
        })

    })


    function VidAutoPlay(mutationsList) {
        mutationsList.forEach(mutation => {

            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {

                const vidLI = mutation.target;
                const oldClasses = mutation.oldValue.split(' ');
                const newClasses = vidLI.className.split(' ');

                // Detect added classes
                newClasses.forEach(cls => {
                    if (!oldClasses.includes(cls)) {
                        if (cls === 'is-active') {
                            var video = vidLI.querySelector('video');
                            if (video)
                                video.play();
                        }
                    }
                });

                // Detect removed classes
                oldClasses.forEach(cls => {
                    if (!newClasses.includes(cls)) {
                        if (cls === 'is-active') {
                            var video = vidLI.querySelector('video');
                            if (video) {
                                video.pause();
                                video.currentTime = 0;
                            }

                        }
                    }
                });
            }
        });
    }

    return (
        <div className='splideCarousel'>
            <Splide  className='mainCarousel'
                options={{
                    arrows: true,
                    autoplay: false,
                    arrowDirection: direction,
                    rewind: true,
                    drag: false,
                    type: type,
                    direction: direction,
                    height: '100%',
                    paginationDirection: 'ltr',
                    aria: aria,
                }}
                aria-label='Categories'>
                {
                    pageList.length > 0 ?
                        pageList.map((object, index, _array) => (
                            <SplideSlide className='slidePage' key={index} aria-label={object[0]}>
                                <NavLink as={Link} to={'/pages/' + object[0]} state={{ id: object[0] }} >
                                    <div className='categoryName'>{object[0]} </div>
                                </NavLink>
                                <Splide className={object[0] === 'VR' ? 'vrCarousel ' : "innerCarousel"} aria-label={object[0]}
                                    options={object[0] === 'VR' ? {
                                        arrows: false,
                                        rewind: true,
                                        type: 'slide',
                                        perPage: 1,
                                        gap: '0',
                                        direction: direction,
                                        height: '100%',
                                        aria: aria,
                                    } : {
                                        arrows: false,
                                        pagination: false,
                                        rewind: true,
                                        type: 'slide',
                                        autoplay: true,
                                        perPage: 2,
                                        gap: '.1em',
                                        drag: 'free',
                                        speed: 0,
                                        interval: 2000,
                                        start: 1,
                                        snap: true,
                                        direction: direction,
                                        height: '100%',
                                        aria: aria,
                                    }} >

                                    {Object.entries(object[1]).map((img, i, _ar) => (
                                        <SplideSlide className={img[1].includes('mp4') ? 'vidSlide' : 'imgSlides'} key={i}>
                                            {
                                                img[1].includes('mp4') ?
                                                    <video
                                                        controls
                                                        src={img[1]}
                                                        type='video/mp4'
                                                        autoPlay
                                                        muted
                                                    >
                                                        Your browser does not support HTML video.
                                                    </video>
                                                    :

                                                    <Link key={'image-' + i}
                                                        to={'/imageViewer/' + img[0]}
                                                        state={{ id: img[0], src: img[1], prevPath: { path: '/', id: '' } }}
                                                        className='thumbnailLink'
                                                    >
                                                        <Image thumbnail src={img[1]} alt={img[0] + '-image'} className='images' />
                                                    </Link>

                                            }
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </SplideSlide>
                        ))
                        :
                        <h1>LOADING...</h1>
                }
            </Splide>
            <Outlet />
        </div>
    );
}

