import * as React from 'react';
import Title from '../Assets/Images/art.png';
import logoImg from '../Assets/Images/Carousel/LogoDesign/SamsonArtLogo.png';
import '../Assets/CSS/HomePage.css';

export default class Logo extends React.Component {

    render() {
        return (
            <div id="logoContainers">
                <div id="title">
                    <div id='samsonText'>
                        SAMSON
                    </div>
                    <div id='titleImg'>
                        <img src={Title} alt='Page Title' />
                    </div>
                </div>
                <div id="logoImg">
                    <img src={logoImg} alt='My Logo' />
                </div>
            </div>
        );
    }
}