import * as React from "react";
import '../Assets/CSS/HomePage.css';
import AddCarousel from "./Carousel";


export default class HomePage extends React.Component {
   
    render() {
        return (

            <div id="homepageContainer" className="pageActive">  
            <svg width="0" height="0" aria-hidden="true">
                    <filter id="grainy" x="0" y="0" width="100%" height="100%">
                        <feTurbulence type="fractalNoise" baseFrequency=".753"></feTurbulence>
                        <feColorMatrix type="saturate" values="0"></feColorMatrix>
                        <feComposite in="SourceGraphic" operator="in"></feComposite>
                    </filter>
                    <filter id="sinset">
                        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                        <feComposite operator="out" in="SourceGraphic" result="inverse"></feComposite>
                        <feFlood floodColor="hsla(0, 0%, 100%, .375)"></feFlood>
                        <feComposite operator="in" in2="inverse"></feComposite>
                        <feComposite operator="over" in2="SourceGraphic"></feComposite>
                    </filter>
                    <filter id="emboss">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="9"></feGaussianBlur>
                        <feSpecularLighting surfaceScale="2" specularConstant=".75" specularExponent="17" lightingColor="#ededed">
                            <fePointLight x="0" y="-29000" z="20000"></fePointLight>
                        </feSpecularLighting>
                        <feComposite in2="SourceAlpha" operator="in"></feComposite>
                        <feComposite in="SourceGraphic" operator="arithmetic" k1="0" k2="1" k3="1" k4="0"></feComposite>
                    </filter>
                </svg>
                <AddCarousel/>
            </div>
        );
    }
}